<template>
  <section id="main-wrapper" style="min-height: 97vh">
    <div class="container">
      <b-alert
        variant="success"
        style="position: fixed; right: 30px; bottom: 10px; z-index: 999"
        :show="d_isAddToCart"
      >
        Session(s) added to cart
        <b-icon-check></b-icon-check>
      </b-alert>
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Packages</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <cmp-packagecard
        v-for="p_sessionPackage in gt_packages"
        :key="p_sessionPackage.id"
        :p_sessionPackage="p_sessionPackage"
        @e_addPackageToCart="m_addPackageToCart"
      />
    </div>
  </section>
</template>

<script>
import c_packageCard from "../cmp-components/cmp-packagecard.vue";
import { mapGetters } from "vuex";

export default {
  name: "cmp-packages",
  components: {
    "cmp-packagecard": c_packageCard,
  },
  computed: {
    ...mapGetters("md_fire", ["gt_packages"]),
  },
  data() {
    return {
      d_isAddToCart: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
    
  },
  methods: {
    async m_addPackageToCart(l_payload) {
      await this.$store.dispatch("md_cart/ac_addPackage", l_payload);
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
      this.d_isAddToCart = true;
      setTimeout(() => {
        this.d_isAddToCart = false;
      }, 2000);
    },
  },
};
</script>