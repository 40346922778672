<template>
  <div class="ed-detail-head">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-5">
          <div class="property-video">
            <div class="thumb">
              <img
                class="pro-img img-fluid w100"
                src="/img/971TutorsImages/packageimg.jpeg"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-md-7">
          <div class="ed-detail-wrap">
            <ul class="cources-facts-list">
              <li v-if="co_hasDiscount" class="facts-5">
                {{ p_sessionPackage.discount }}% discount
              </li>
            </ul>
            <div class="ed-header-caption">
              <h2 class="ed-title">{{ p_sessionPackage.title }}</h2>
              <ul>
                <li
                  v-if="co_hasDiscount"
                  style="
                    text-decoration: line-through;
                    color: red;
                    font-style: italic;
                  "
                >
                  <i class="ti-calendar"></i>AED
                  {{ co_packagePrice }}
                </li>
                <li>
                  <i v-if="co_hasDiscount" class="ti-control-forward"></i
                  ><strong>AED {{ p_sessionPackage.price }}</strong>
                </li>
                <!-- <li>
                  <i class="ti-user"></i
                  >{{ p_sessionPackage.quantityBought }} Students bought this
                  package
                </li> -->
              </ul>
            </div>
            <div class="ed-header-short">
              <p>
                A package of {{ p_sessionPackage.sessions }} sessions is valued
                at <strong>AED {{ co_packagePrice }}</strong
                >.
                <span v-if="co_hasDiscount">
                  After the
                  <strong>{{ p_sessionPackage.discount }}%</strong> discount, its
                  value will be equal to
                  <strong>AED {{ p_sessionPackage.price }} </strong></span
                >
              </p>
            </div>
            <button
              type="button"
              @click="m_addPackageToCart"
              class="btn btn-theme mt-3"
            >
              Add To Cart <b-icon-cart4 style="margin-left: 5px"></b-icon-cart4>
            </button>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  name: "cmp-packagecard",
  props: {
    p_sessionPackage: { type: Object },
  },
  computed: {
    co_packagePrice() {
      return !this.hasDiscount ? this.p_sessionPackage.actualValue : this.p_sessionPackage.price;
    },
    co_hasDiscount() {
      return this.p_sessionPackage.discount > 0;
    },
  },
  methods: {
    m_addPackageToCart() {
      this.$emit("e_addPackageToCart", {
        ...this.p_sessionPackage,
        quantity: 1,
      });
    },
  },
};
</script>

<style>
</style>